.transactions{
    font-size: 30px;
}
.boostContainer{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-top: 24px;
    overflow-x: hidden;
}
.boostWrapper{
    display: flex;
    padding-left: 2%;
    flex-wrap: wrap;
    width: 102%;
    row-gap: 24px;
    column-gap: -3px;
}
.boostContainer h3{
    font-size: 20px;
}
.boost{
    background-color: red;
    width: 21.6%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: -2%;
    border-radius: 16px;
    border: 1px solid rgba(75, 85, 99, 1);
}
.boost:hover{
    transform: translateX(-5%);
    transition: 200ms;
    cursor: pointer;
}
.boostOverlay{
    align-items: center;
    flex-direction: column;
    display: flex;
    row-gap: 36px;
    justify-content: center;
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.358);
}

.boostTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 30px;
    background-color: rgba(43, 43, 43, 1);
    column-gap: 16px;
    font-size: 16px;
    border: 0px;
    text-align: center;
    border-radius: 8px;
}
.boost button{
    padding: 10px 30px;
    padding-bottom: 12px;
    gap: 16px;
    border-radius: 8px;
    background-color: rgba(255, 225, 96, 1);
    color: black;
    border: 0px;
    font-weight: 600;
    width: fit-content;
    font-size: 15px;

}
.historyWrapper{
    padding-top: 72px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.historyWrapper h3{
    font-size: 20px;
}
.tableWrapper{
    overflow-x: scroll;
    width: 100%;
}
.tableWrapper::-webkit-scrollbar{
    background-color: black;
    height: 5px;
}
.tableWrapper::-webkit-scrollbar-thumb{
    background-color: rgba(59, 59, 59, 1);
}

.tableWrapper table{
    width: 100%;
    min-width: 550px;
    border-collapse: collapse;
    border: 1px solid rgba(75, 85, 99, 1);
}
.tableWrapper thead{
    background-color: var(--dashboard-background);

}
.tableWrapper tr{
    border-bottom: 1px solid rgba(75, 85, 99, 1);
}
.tableWrapper th{
    padding: 16px;
    font-size: 16px;
}
.tableWrapper td{
    font-size: 16px;
    padding: 16px;
    text-align: center;
}

tr button{
    height: 30px;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
    color: black;
    transform: skew(-10deg);
    border: 2px solid white;
    background-color: rgba(255, 225, 96, 1);
}


@media(max-width: 1400px){
    .boost{
        width: 26.5%;
    }
}
@media(max-width: 1200px){
    .boost{
        width: 34.6%;
    }
}
@media(max-width: 750px){
    .transactions{
        font-size: 22px;
    }
    .boostContainer h3{
        font-size: 16px;
        font-weight: 500;
    }
    .boostOverlay{
        row-gap: 26px;
    }
    .boostTitle{
        padding: 10px 24px;
        font-size: 14px;
    }
    .boost button{
        padding: 8px 20px;
        padding-bottom: 10px;
        font-size: 12px;
    }
    .boost h1{
        font-size: 30px;
    }
    .historyWrapper h3{
        font-size: 16px;
        font-weight: 500;
    }
    .tableWrapper th{
        font-size: 14px;
        font-weight: 500;
    }
    .tableWrapper td{
        font-size: 14px;
        font-weight: 500;
    }
}
@media(max-width: 600px){
    .boost{
        width: 51%;
    }
    .boostOverlay{
        row-gap: 20px;
    }
    .boostTitle{
        padding: 10px 15px;
        font-size: 13px;
    }
    .transactions{
        font-size: 20px;
    }
    
}

@media(max-width: 400px){
    .transactions{
        font-size: 18px;
    }
    .boost{
        width: 51%;
    }
    .boostOverlay{
        row-gap: 15px;
    }
    .boostTitle{
        padding: 10px 15px;
        font-size: 13px;
    }
    .tableWrapper th{
        font-size: 13px;
        font-weight: 500;
        padding: 14px;
    }
    .tableWrapper td{
        font-size: 13px;
        padding: 14px;
        font-weight: 500;
    }
    .tableWrapper table{
        min-width: 400px;
    }
}
