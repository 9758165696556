.profile{
    font-size: 30px;
}
.profileWrapper{
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    column-gap: 3%;
}
.summaryWrapper{
    width: 48.5%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.summaryWrapper h4{
    font-size: 20px;
    font-weight: 500;
}
.summaryCards{
    display: flex;
    column-gap: 3%;
    flex-wrap: wrap;
    row-gap: 20px;
}
.summaryCards .sumCard{
    width: 48.5%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    background-color: rgba(59, 59, 59, 1);
    border-radius: 16px;
    align-items: center;
}
.cardTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 10px;
    background-color: rgba(43, 43, 43, 1);
    column-gap: 16px;
    border-radius: 8px;
}
.cardTitle img{
    height: 40px;
    width: auto;
    object-fit: cover;
}
.cardTitle p span{
    color: green;
    font-size: 14px;
}
.cardTitle p{
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.sumCard h2{
    font-size: 28px;
    font-weight: 600;
    display: flex;
    column-gap: 16px;
    align-items: flex-end;
}
.sumCard h2 small{
    font-size: 14px;
    font-weight: 400;
}
.sumCard h2 img{
    height: 16px;
    width: auto;
    object-fit: cover;
}
.userInformation{
    width: 48.5%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.userInformation h4{
    font-size: 20px;
    font-weight: 500;
}
.userInfoWrapper{
    padding: 32px 24px;
    border: 1px solid rgba(75, 85, 99, 1);
    display: flex;
    border-radius: 16px;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
}
.userInfoWrapper p{
    font-size: 16px;
    width: 100%;
}
.userInfoWrapper button{
    font-size: 14px;
    padding: 12px 46px;
    width: fit-content;
    border-radius: 8px;
    border: 0px;
    background-color: rgba(255, 225, 96, 1);
}
.profileStatus{
    display: flex;
    align-items: center;
    column-gap: 16px;
    justify-content: left;
    width: 100%;
}
.profileStatus p{
    width: 180px;
}
.profileProgress{
    background-color: var(--dashboard-background);
    display: flex;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    align-items: center;
    column-gap: 16px;
}
.profileProgress span{
    font-size: 16px;
}

.currentTask{
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
}
.userInfoWrapper form{
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
    align-items: center;
}
.userInfoWrapper .inputWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 14px;
}
.userInfoWrapper label{
    font-size: 16px;
}
.userInfoWrapper input{
    border-radius: 8px;
    background-color: white;
    padding: 8px 16px;
    font-size: 15px;
    color: rgba(75, 85, 99, 1);
    outline: none;
    border: 1px solid white;
}
.userInfoWrapper input:focus{
    border: 1px solid var(--dashboard-yellow);
}
.userInfoWrapper select{
    border-radius: 8px;
    background-color: white;
    padding: 8px 16px;
    font-size: 15px;
    color: rgba(75, 85, 99, 1);
    outline: none;
    border: 1px solid white;
}
.userInfoWrapper select:focus{
    border: 1px solid var(--dashboard-yellow);
}

.error{
    color: red;
}

@media(max-width: 1300px){
    .profileWrapper{
        flex-direction: column;
        row-gap: 24px;
    }
    .summaryWrapper{
        width: 100%;
    }
    .userInformation{
        width: 100%;
    }
}
@media(max-width:1000px){
    .profile{
        font-size: 25px;
    }
    .profileWrapper h4{
        font-size: 16px;
    }
}
@media(max-width: 600px){
    .currentTask{
        width: 100%;
    }
    .userInfoWrapper p{
        font-size: 14px;
    }
    .profileStatus p{
        font-size: 14px;
        width: 130px;
    }
    .userInfoWrapper label{
        font-size: 14px;
    }
    .userInfoWrapper input{
        font-size: 14px;
        padding: 7px 10px;
        border-radius: 5px;
    }
    .userInfoWrapper select{
        font-size: 14px;
        padding: 7px 10px;
        border-radius: 5px;
    }
    .profileProgress span{
        font-size: 14px;
    }
    .profileProgress{
        padding: 12px 14px;
    }
}
@media(max-width: 500px){
    .cardTitle p{
        font-size: 13px;
    }
    .cardTitle p span{
        font-size: 13px;
    }
    .summaryCards .sumCard{
        padding: 25px 10px;
    }
    .sumCard h2{
        font-size: 20px;
    }
    .userInfoWrapper button{
        padding: 10px 30px;
        font-size: 12px;
    }
}
@media(max-width: 400px){
    .profileStatus{
        flex-direction: column;
        row-gap: 8px;
        align-items: flex-start;
    }
}