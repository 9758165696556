@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900');

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Lato", system-ui;
}
.body{
  padding: 0px 3%;
  color: white;
  background-color: #2F2C32;
}
:root{
  --yellow: #d8aa13;
  --dashboard-yellow:  #FFD210;
  --dashboard-background:  #3B3B3B;
  --title-background:  rgba(43, 43, 43, 1);
  --light-background: #2F2C32;
}
.walletBlc {
  display: flex;
  justify-content: right;
}
.walletBlc .walletBrief{
  display: none;
  column-gap: 15px;
  align-items: center;
  background-color: var(--dashboard-background);
  border-radius: 8px;
  padding: 5px 10px;
  color: white;
  width: fit-content;
  margin-bottom: 30px;
  margin-top: -20px;
}
.walletBlc .walletBrief:nth-child(1) img{
  height: 20px;
  width: auto;
  object-fit: cover;
}
.walletBlc p{
  font-size: 13px;
  font-weight: 500;
  display: flex;
  column-gap: 7px;
  font-weight: 600;
  align-items: flex-end;
}
.walletBlc span{
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1px;
}
.walletBlc small{
  font-size: 9px;
}
body{
  background-color: black;
}
main{
  margin-left: 300px;
  padding:  60px 80px;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  color: white;
}

@media(max-width: 1300px){
  main{
    padding: 50px 5%;
  }
}
@media(max-width: 1000px){
  main{
    margin-left: 0px;
  }
}

@media(max-width: 850px){
  .body{
    padding: 0px;
  }
}

@media(max-width:650px){
  .walletBlc .walletBrief{
    display: flex;
  }
}