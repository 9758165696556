.sidebar{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0px;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    justify-content: space-between;
    padding: 40px 30px;
    width:300px;
    border-width: 0px 1px 0px 0px;
    background-color:var(--dashboard-background);
}
.sidebarNavWrapper{
    border-bottom: 1px solid rgba(255, 255, 255, 0.275);
    margin-left: 300px;
    padding: 25px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
}
.sidebarNav{
    display: flex;
    width: fit-content;
    column-gap: 20px;
}
.sidebarNavLogo img{
    display: none;
}
.sidebarNav button{
    background-color: var(--dashboard-yellow);
    color: black;
    border: 0px;
    padding: 9px 22px;
    padding-bottom: 12px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
}
.sidebarNav .walletBrief{
    display: flex;
    column-gap: 15px;
    align-items: center;
    background-color: var(--dashboard-background);
    border-radius: 8px;
    padding: 7px 20px;
    color: white;
}
.sidebarNav .walletBrief:nth-child(1) img{
    height: 28px;
    width: auto;
    object-fit: cover;
}
.sidebarNav p{
    font-size: 15px;
    font-weight: 500;
    display: flex;
    column-gap: 7px;
    font-weight: 600;
    align-items: flex-end;
}
.sidebarNav span{
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
}
.sidebarNav small{
    font-size: 10px;
}
.sidebar .logo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar img{
    height: auto;
    width: 120px;
}
.sidebar .topSidebar{
    display: flex;
    flex-direction: column;
    row-gap: 80px;
    width: 100%;
}
.sidebar .navigations{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.sidebar a{
    display: flex;
    align-items: center;
    padding: 10px;
    column-gap: 12px;
    font-size: 15px;
    width: 100%;
    color: white;
    text-decoration: none;
    font-weight: 500;
    border-radius: 8px;
}
.sidebar a:hover{
    background-color: white;
    color: black;
}
.sidebar a svg{
    font-size: 24px;
    font-weight: 600;
}
.sidebar .bottomSidebar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    padding-bottom: 0px;
    border-width: 1px 0px 0px 0px;
    position: relative;
}
.sidebar .bottomSidebar svg{
    cursor: pointer;
}
.sidebar .userMenu{
    position: absolute;
    left: 90px;
    bottom: 35px;
    border-width: 1px;
    background-color: white;
    width: 140px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.037);
    border-radius: 7px;
}
.sidebar .userMenu a:hover{
    color: var(--chocolateWhite);
}
.sidebar .bottomSidebar img{
    width: 30px;
    height: 30px;
    background-color: grey;
    border-radius: 100%;
}
.sidebar .user{
    display: flex;
    align-items: center;
    column-gap: 7px;
}
.sidebar .userInfo{
    display: flex;
    flex-direction: column;
    row-gap: 0px;
}
.sidebar .userInfo h3{
    font-size: 13px;
    font-weight: 600;
}
.sidebar .userInfo p{
    font-size: 12px;
    margin-top: -3px;
}
.hide{
    display: none;
}
.responsiveSidebar{
    display: none;
}
.menu{
    display: none;
}
.sidebar button{
    display: none;
    text-align: center;
}
@media(max-width: 1300px){
    .sidebarNavWrapper{
        padding: 20px 5%;
    
    }

}
@media(max-width: 1000px){
    .sidebarNavWrapper{
        margin-left: 0px;
        padding: 20px 5%;

    }
    .sidebar button{
        background-color: var(--dashboard-yellow);
        color: black;
        border: 0px;
        justify-content: center;
        align-items: center;
        padding: 9px 22px;
        display: flex;
        padding-bottom: 12px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 600;
    }
    .sidebarNavLogo img{
        display: flex;
        height: 30px;
        width: auto;
        object-fit: cover;
    }
    .sidebarNav .menu{
        display: flex;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sidebarNav .menu svg{
        font-size: 20px;
        cursor: pointer;
    }
    .sidebarNav button{
        display: none;
    }
    .sidebarNav .walletBrief:nth-child(1) img{
        height: 20px;
        width: auto;
    }
    .sidebarNav .walletBrief:nth-child(2) img{
        height: 20px;
        width: auto;
    }
    .sidebarNav p{
        font-size: 13px;
    }
    .sidebarNav span{
        font-size: 13px;
    }
    .sidebarNav .walletBrief{
        padding: 5px 10px;
    }
    .sidebarNav small{
        font-size: 9px;
    }
    .sidebarNav svg{
        font-size: 13px;
        cursor: pointer;
    }
    .sidebar{
        width: 250px;
        padding: 40px 20px;
    }
    .sidebar .topSidebar{
        row-gap: 0px;
    }
    .sidebar .topSidebar img{
        display: none;
    }
    .sidebar a{
        font-size: 14px;
        font-weight: 500;
    }
    .sidebar a svg{
        font-size: 20px;
    }
    .responsiveSidebar{
        padding: 10px 3%;
        display: flex;
        align-items: center;
        position: sticky;
        justify-content: space-between;
        top: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        border-width: 0px 0px 1px 0px;
    }
    .responsiveSidebar svg{
        font-size: 19px;
        cursor: pointer;
    }
    .responsiveSidebar img{
        width: 30px;
        height: auto;
    }
}
@media(max-width: 650px){
    .sidebarNav{
        column-gap: 10px;
    }
    .sidebarNav .walletBrief2{
        display: none;
    }
}