.welcomeWrapper{
    width: 100%;
    height: fit-content;
    background-image: url("../assets/images/welcomebackgrund.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 48px;
    border-radius: 16px;
}
.leftWelcome{
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    color: white;
    width: 55%;
}
.leftWelcome h2{
    font-size: 32px;
    font-weight: 500;
}
.leftWelcome p{
    font-size: 16px;
}
.rightWelcome{
    width: 40%;
    display: flex;
    justify-content: right;
    height: fit-content;
}
.rightWelcome img{
    width: auto;
    height: 168px;
    object-fit: cover;
}
.prizepoolWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-top: 72px;
}
.prizepoolWrapper h3{
    font-size: 20px;
    font-weight: 600;
}
.cardWrapper{
    display: flex;
    column-gap: 2%;
    flex-wrap: wrap;
}
.cardWrapper .card{
    width: 32%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    background-color: rgba(59, 59, 59, 1);
    border-radius: 16px;
    align-items: center;
}
.cardTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 10px;
    background-color: rgba(43, 43, 43, 1);
    column-gap: 16px;
    border-radius: 8px;
}
.cardTitle img{
    height: 40px;
    width: auto;
    object-fit: cover;
}
.card h2{
    font-size: 32px;
    font-weight: 600;
    display: flex;
    column-gap: 16px;
    align-items: flex-end;
}
.card h2 small{
    font-size: 16px;
    font-weight: 400;
}
.card h2 img{
    height: 20px;
    width: auto;
    object-fit: cover;
}
.wrapper{
    display: flex;
    column-gap: 2%;
    justify-content: space-between;
    padding-top: 70px;
}
.leftWrapper{
    width: 49%;
    border: 1px solid rgba(59, 59, 59, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    row-gap: 40px;
    border-radius: 16px;
    justify-content: space-between;
}
.wrapper .title{
    background-color: var(--title-background);
    width: 240px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    flex-wrap: wrap;
    row-gap: 5px;
}
.wrapper .title2{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.wrapper .title p{
    font-size: 16px;
    font-weight: 600;
}
.wrapper .title span{
    font-size: 12px;
    font-weight: 500;
}
.rightWrapper{
    width: 49%;
    display: flex;
    border-radius: 16px;
    border: 1px solid rgba(59, 59, 59, 1);
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}
.buySteps{
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    width: 100%;
    text-align: left;
}
.rightWrapper button{
    background-color: var(--dashboard-yellow);
    padding: 10px 35px;
    padding-bottom: 12px;
    border: 0px;
    color: black;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
}
.taskContainer{
    width: 100%;
    overflow-y: scroll;
    height: 220px;
}
.taskContainer::-webkit-scrollbar{
    background-color: black;
    width: 2px;
}
.taskContainer::-webkit-scrollbar-thumb{
    background-color: rgba(59, 59, 59, 1);
}
.taskWrapper{
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 5px 0px;
    margin-right: 50px;
}
.task{
    background-color: white;
    width: 100%;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    row-gap: 16px;
}
.taskTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.taskTitle p{
    display: flex;
    column-gap: 3px;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: rgba(75, 85, 99, 1);
}
.progressBar{
    width: 100%;
    height: 12px;
    border-radius: 8px;
    background-color: rgba(75, 85, 99, 1);
}
.progressLoad{
    height: 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}


.shareWrapper{
    display: flex;
    column-gap: 2%;
    justify-content: space-between;
}
.shareWrapper .title{
    background-color: var(--title-background);
    width: 90%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    flex-wrap: wrap;
    row-gap: 5px;
}
.shareWrapper .title p{
    font-size: 15px;
    font-weight: 600;
}
.shareWrapper .title span{
    font-size: 12px;
    font-weight: 500;
}
.shareWrapper .rightWrapper{
    background-color: var(--dashboard-background);
    row-gap: 20px;
}
.rightWrapper .count{
    display: flex;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    color: var(--dashboard-background);
    width: 100%;
}
.rightWrapper .count p{
    font-size: 15px;
}
.rightWrapper .count svg{
    font-size: 18px;
}
.usersContainer{
    width: 100%;
    overflow-y: scroll;
    height: 260px;
}
.usersContainer::-webkit-scrollbar{
    background-color: black;
    width: 2px;
}
.usersContainer::-webkit-scrollbar-thumb{
    background-color: rgba(59, 59, 59, 1);
}
.usersWrapper{
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}
.usersWrapper .users{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    position: relative;
    align-items: center;
}
.usersWrapper img{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: gray;
}
.usersWrapper .position{
    position: absolute;
    top: 3px;
    right: 15px;
    z-index: 20;
}
.usersWrapper span{
    width: 22px;
    height: 22px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    background-color: var(--dashboard-background);
}
.usersWrapper h3{
    font-size: 15px;
    font-weight: 600;
}
.usersWrapper p{
    font-size: 18px;
    margin-top: -2px;
    color: rgba(75, 85, 99, 1);
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.usersWrapper p img{
    width: 16px;
    height: auto;
    object-fit: cover;
    background-color: transparent;
    border-radius: 0px;
}
.taskTitle button{
    height: 30px;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: white;
    transform: skew(-10deg);
    border: 2px solid white;
    background-color: #2b2b2b !important;
}

@media(max-width:1300px){
    .welcomeWrapper{
        padding: 16px 30px;
    }
    .leftWelcome{
        width: 65%;
    }
    .rightWelcome{
        width: 35%;
    }
    .leftWelcome h2{
        font-size: 25px;
    }
    .leftWelcome p{
        font-size: 14px;
    }
    .rightWelcome img{
        height: 130px;
    }
    .prizepoolWrapper h3{
        font-size: 16px;
    }
    .cardWrapper .card{
        padding: 20px;
        row-gap: 20px;
    }
    .cardTitle{
        width: 100%;
        padding: 8px;
    }
    .cardTitle img{
        height: 30px;
    }
    .cardTitle p{
        font-size: 14px;
    }
    .card h2{
        font-size: 28px;
    }
    .card h2 small{
        margin-bottom: 5px;
        font-size: 13px;
    }
    .card h2 img{
        height: 18px;
        margin-bottom: 5px;
    }
    .leftWrapper{
        padding: 24px 15px;
        row-gap: 30px;
    }
    .wrapper .title{
        padding: 10px 14px;
        border-radius: 5px;
    }
    .wrapper .title p{
        font-size: 14px;
    }
    .shareWrapper .title{
        padding: 10px 14px;
        border-radius: 5px;
    }
    .shareWrapper .title p{
        font-size: 14px;
    }
    .rightWrapper{
        padding: 24px 15px;
    }
    .rightWrapper button{
        padding: 8px 30px;
        font-size: 13px;
        border-radius: 5px;
        padding-bottom: 9px;
    }
    .rightWrapper p{
        font-size: 14px;
        line-height: 24px;
    }
    .taskWrapper{
        margin-right: 10px;
        row-gap: 15px;
    }
    .taskTitle p{
        font-size: 13px;
    }
    .task{
        row-gap: 12px;
    }
    .shareleftWrapper{
        justify-content: flex-start;
        row-gap: 40px;
    }
    .usersContainer{
        height: 300px;
    }
    .usersWrapper h3{
        font-size: 14px;
    }
    .usersWrapper p{
        font-size: 17px;
    }
    .usersWrapper p img{
        width: 14px;
    }
}
@media(max-width: 1000px){
    .usersContainer{
        height: 220px;
    }
}

@media(max-width:750px){
    .rightWelcome img{
        width: 100%;
        height: auto;
    }
    .cardWrapper{
        row-gap: 15px;
    }
    .cardWrapper .card{
        width: 49%;
    }
    .cardWrapper .card:last-child{
        width: 100%;
    }
    .wrapper{
        flex-direction: column;
        row-gap: 30px;
    }
    .shareWrapper{
        flex-direction: column;
        row-gap: 30px;
    }
    .shareWrapper .rightWrapper{
        row-gap: 30px;
    }
    .leftWrapper{
        width: 100%;
    }
    .rightWrapper{
        width: 100%;
        row-gap: 30px;
    }
    .task{
        padding: 8px 12px;
    }
}
@media(max-width: 600px){
    .welcomeWrapper{
        flex-direction: column;
        row-gap: 30px;
        align-items: center;
        text-align: center;
        padding: 25px 15px;
    }
    .leftWelcome{
        width: 100%;
        row-gap: 10px;
    }
    .rightWelcome{
        width: 100%;
        justify-content: center;
    }
    .rightWelcome img{
        width: 200px;
        height: auto;
    }
    .cardTitle img{
        height: 25px;
    }
    .cardTitle p{
        font-size: 13px;
    }
    .card h2{
        font-size: 25px;
    }
    .card h2 small{
        margin-bottom: 3px;
        font-size: 13px;
    }
    .card h2 img{
        height: 16px;
        margin-bottom: 3px;
    }
}
@media(max-width:500px){
    .leftWelcome h2{
        font-size: 22px;
    }
    .leftWelcome p{
        font-size: 13.5px;
    }
    .cardWrapper .card{
        width: 100%;
    }
    .prizepoolWrapper{
        padding-top: 60px;
    }
    .wrapper .title{
        width: 100%;
        flex-wrap: wrap;
        row-gap: 10px;
    }
    .shareWrapper .title{
        width: 100%;
        flex-wrap: wrap;
        row-gap: 10px;
    }
    .wrapper .title2{
        padding: 7px 10px;
    }
    .rightWrapper .count{
        padding: 8px 15px;
        border-radius: 6px;
    }
    .rightWrapper .count p{
        font-size: 14px;
    }
}
@media(max-width: 400px){
    .cardWrapper{
        flex-direction: column;
    }
    .cardWrapper .card{
        width: 100%;
    }
}