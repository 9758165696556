
/* transform: skew(-10deg);
clip-path: polygon(7% 0%, 100% 0%, 93% 100%, 0% 100%); */


nav{
    display: flex;
    justify-content: space-between;
    padding: 30px 10%;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 20;
    background-color: #40320437;
    right: 0px;
}
.navScrolled{
    background-color: var(--yellow);
    padding: 15px 10%;
    z-index: 20;
}
nav .logo{
    width: auto;
    height: 50px;
}
nav .logo img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
nav .userName{
    display: flex;
    flex-direction: column;
    row-gap: 0px;
}
nav .userName h3{
    display: flex;
    align-items: flex-start;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 2px;
}
nav .menu{
    display: flex;
    align-items: flex-start;
    column-gap: 30px;
}
nav .userName h3 span{
    background-color: red;
    color: white;
    font-size: 10px;
    width: 16px;
    margin-top: -6px;
    border-radius: 100%;
    height: 16px;
    padding-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
nav .userName p{
    font-size: 13px;
    color: rgba(255, 255, 255, 0.785);
}
nav .account{
    display: flex;
    align-items: center;
    font-size: 18px;
    position: relative;
    cursor: pointer;
}
nav .account svg:nth-child(2){
    font-size: 13px;
}
.accountLogin{
    z-index: 30;
    position: absolute;
    top: 80px;
    right: 10%;

}
.accountLogin form{
    background-color: black;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.accountLogin label{
    font-size: 15px;
}
.accountLogin .inputWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.accountLogin input{
    width: 250px;
    clip-path: polygon(3% 0%, 100% 0%, 97% 100%, 0% 100%);
    padding: 12px 20px;
    background-color: var(--light-background);
    border: 0px;
    font-size: 15px;
}
.accountLogin .inputSearch{
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.accountLogin .inputSearch input{
    background-color: var(--light-background);
    transform: skew(-10deg);
    width: 20px;
    height: 20px;
}
.accountLogin input:focus{
    background-color: white;
}
.accountLogin::before{
    content: '';
    width: 20px;
    height: 10px;
    background-color: black;
    text-align: right;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    position: absolute;
    right: 15px;
    top: -7px;
}
.hide{
    display: none;
}
.accountLogin button{
    height: 35px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: white;
    transform: skew(-10deg);
    border: 2px solid white;
    background-color: transparent;
}
.accountLogin button:hover{
    border: 2px solid var(--yellow);
    transition: 500ms;
    background-color: var(--yellow);
}
.accountLogin p{
    font-size: 14px;
    display: flex;
    column-gap: 10px;
    width: fit-content;
}
.accountLogin p span:hover{
    color: var(--yellow);
    cursor: pointer;
}
.accountLogin p span{
    width: fit-content;
}

.registerWrap{
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    background-color: #2f2c3249;
    z-index: 40;
    backdrop-filter: blur(1px);
}
.registerWrap form{
    background-color: black;
    margin-top: 90px;
    height: fit-content;
}
.registerWrap form{
    background-color: black;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.registerWrap label{
    font-size: 15px;
}
.registerWrap .inputWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.registerWrap input{
    width: 250px;
    clip-path: polygon(3% 0%, 100% 0%, 97% 100%, 0% 100%);
    padding: 12px 20px;
    background-color: var(--light-background);
    border: 0px;
    font-size: 15px;
}
.registerWrap .inputSearch{
    display: flex;
    column-gap: 10px;
    align-items: center;
}
.registerWrap .inputSearch input{
    background-color: var(--light-background);
    transform: skew(-10deg);
    width: 20px;
    height: 20px;
}
.registerWrap input:focus{
    background-color: white;
}
.hide{
    display: none;
}
.registerWrap button{
    height: 35px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: white;
    transform: skew(-10deg);
    border: 2px solid white;
    background-color: transparent;
}
.registerWrap button:hover{
    border: 2px solid var(--yellow);
    transition: 500ms;
    background-color: var(--yellow);
}
.registerWrap p{
    font-size: 15px;
    display: flex;
    column-gap: 10px;
    width: fit-content;
}
.registerWrap p span:hover{
    color: var(--yellow);
    cursor: pointer;
}
.registerWrap p span{
    width: fit-content;
}
.registerWrap h2{
    /* padding-top: px; */
    padding: 0px 20px;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-bottom: 30px;
    margin: 0px -20px;
    border-bottom: 1px solid white;
}
.registerWrap h2 span:hover{
    color: var(--yellow);
    cursor: pointer;
}
.menu button{

    height: 30px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    color: white;
    border-color: white;
    background-color: black;
    transform: skew(-10deg);
}
@media(max-width: 1300px){
    nav{
        padding: 30px 5%;
    }
    .navScrolled{
        padding: 15px 5%;
    }
}