.hero{
    background-image: url("../assets/images/ghghhh without moon-2.png");
    height: fit-content;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    position: relative;
}
.heroOverlay{
    height: 90vh;
    padding: 0px 10%;
    background-color: #0000006c;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    justify-content: center;
}
.cryptoWrap{
    background-color: #2F2C32;
    margin: 0px 5%;
    padding: 0px 25px;
    border-radius: 10px;
    bottom: -45px;
    left: 0px;
    right: 0px;
    display: flex;
    column-gap: 20px;
    align-items: center;
    position: absolute;
}
.cryptoWrap .arrow{
    font-size: 30px;
}
.cryptoWrap .arrow:hover{
    color: var(--yellow);
    cursor: pointer;
}
.crypto{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    width: 100%;
    padding: 15px 25px;
}
.cryptoCard{
    display: flex;
    flex-direction: column;
    row-gap: 3px;
}
.cryptoCard p{
    color: var(--yellow);
    font-size: 13px;
    letter-spacing: 1px;
}
.cryptoCard h3{
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
}
.cryptoCard span{
    letter-spacing: 1px;
    color: rgb(10, 181, 10);
    font-size: 12px;
    align-items: center;
    display: flex;
    column-gap: 3px;
    font-weight: 600;
}
.heroOverlay h1{
    font-size: 45px;
    font-weight: 400;
    letter-spacing: 1px;
}
.heroOverlay p{
    font-size: 21px;
    font-weight: 300;
    width: 750px;
    line-height: 30px;
}
.heroOverlay .buttonWrap{
    display: flex;
    column-gap: 20px;
    margin-top: 10px;
}
.heroOverlay .buttonWrap button{
    border: 0px;
    height: 50px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: white;
    transform: skew(-10deg);
}
.heroOverlay button:nth-child(1){
    background-color: var(--yellow);
}
.heroOverlay button:nth-child(2){
    border: 2px solid white;
    background-color: transparent;
}
.heroOverlay button:nth-child(2):hover{
    border: 2px solid var(--yellow);
    transition: 500ms;
    background-color: var(--yellow);
}

.cardsWrapper{
    background-color: black;
    padding: 70px 10%;
    display: flex;
    row-gap: 20px;
    padding-top: 150px;
    justify-content: space-between;
}
.cardsWrapper .card{
    width: 49%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    padding: 30px;
    clip-path: polygon(7% 0%, 100% 0%, 93% 100%, 0% 100%);
    background-color: #171618;
    text-align: center;
}
.cardsWrapper .card h3{
    font-size: 24px;
    font-weight: 400;
}
.cardsWrapper .card p{
    font-size: 14px;
    line-height: 20px;
}
.cardsWrapper svg{
    font-size: 45px;
    color: var(--yellow);
}
.cardsWrapper img{
    height: 45px;       
    width: auto;
}
.accumulate{
    padding: 0px 10%;
    margin-top: 00px;
    background-color: black;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
}
.accumulate a{
    color: white;
    color: var(--yellow);
}
.demoWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    text-align: center;
    background-color: black;
    margin-top: -2px;
    padding: 100px 10%;
}
.demoWrapper h3{
    font-size: 25px;
    font-weight: 400;
    line-height: 35px;
    width: 80%;
}
.demoCards{
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    row-gap: 50px;
    width: 700px;
}
.demoCards .demoCard{
    width: 48%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.demoCard img{
    width: 100%;
    height: auto;
    object-fit: cover;
}
.demoCard p{
    font-size: 20px;
}
.accumulate2{
    padding: 0px 10%;
    margin-top: -50px;
    text-align: center;
    font-size: 18px;
    padding-bottom: 150px;
    background-color: black;
    font-weight: 300;
}
.accumulate2 a{
    color: white;
    color: var(--yellow);
}

.bottomHero{
    position: relative; 
    height: auto;
}
.bottomHero .moon{
    position: sticky;
    top: 0; /* Position it at the top */
    left: 100%; /* Center horizontally */
    z-index: 0; /* Keeps the moon behind other scrolling content */
    width: 600px; /* Adjust size */
    height: auto;
}
.bottomHeroOverlay{
    background-color: #0808089e;
    position: relative;
    z-index: 10px;
    margin-top: -785px;
}
.bottomCards{
    padding: 100px 10%;
    margin-top: -3px;
    display: flex;
    flex-direction: column;
    row-gap: 50px;

}
.bottomCards h2{
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 1px;
}

.bottomCardsWrapper{
    display: flex;
    row-gap: 20px;
    justify-content: space-between;
}
.bottomCardsWrapper .bottomCard{
    width: 49%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    padding: 30px;
    clip-path: polygon(7% 0%, 100% 0%, 93% 100%, 0% 100%);
    background-color: #2F2C32;
    text-align: center;
}
.bottomCardsWrapper .bottomCard h3{
    font-size: 24px;
    font-weight: 400;
}
.bottomCardsWrapper .bottomCard p{
    font-size: 14px;
    line-height: 20px;
}
.bottomCardsWrapper svg{
    font-size: 45px;
    color: var(--yellow);
}
.questions{
    padding: 100px 10%;
    margin-top: -3px;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    background-color: black;
    
}
.questions h2{
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 1px;
}
.partners{
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    align-items: flex-start;
}
.partners img{
    width: auto;
    height: 60px;
    object-fit: cover;
}
.sponsors{
    background-color: #0808089e;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    padding: 100px 10%;
}
.sponsors h1{
    font-size: 50px;
    font-weight: 400;
    letter-spacing: 1px;
}
.sponsors p{
    font-size: 14px;
    font-weight: 400;
}
.sponsors  button{
    border: 0px;
    height: 50px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: white;
    transform: skew(-10deg);
    border: 2px solid white;
    background-color: transparent;
}
.sponsors button:hover{
    border: 2px solid var(--yellow);
    transition: 500ms;
    background-color: var(--yellow);
}

.connect{
    padding: 70px 10%;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
}
.connect h3{
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 1px;
}
.connectCardWrapper{
    display: flex;
    column-gap: 60px;
    flex-wrap: wrap;
}
.connectCard{
    width: 80px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 10px;
    cursor: pointer;
    align-items: center;
    
}
.connectCard svg{
    padding: 20px;
    font-size: 30px;
    width: 120px;
    clip-path: polygon(7% 0%, 100% 0%, 93% 100%, 0% 100%);
    height: 90px;
    background-color: #2F2C32;
}

footer{
    display: flex;
    justify-content: space-between;
    padding: 30px 10%;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.83);
    font-size: 16px;
}
footer .rightFooter{
    display: flex;
    column-gap: 30px;
    cursor: pointer;
}
footer .rightFooter small:hover{
    color: var(--yellow);
}


@media(max-width: 1300px){
    .heroOverlay{
        padding: 0px 5%;
    }
    .cardsWrapper{
        padding: 70px 5%;
    }
    .accumulate{
        padding: 0px 5%;
    }
    .demoWrapper{
        padding: 100px 5%;
    }
    .accumulate2{
        padding: 0px 5%;
        padding-bottom: 150px;
    }
    .bottomCards{
        padding: 100px 5%;
    }
    .questions{
        padding: 100px 5%;
    }
    .sponsors {
        padding: 100px 5%;
    }
    .connect{
        padding: 70px 5%;
    }
    footer{
        padding: 30px 5%;
    }

}
@media(max-width: 1000px){
    .heroOverlay h1{
        font-size: 35px;
    }
    .heroOverlay p{
        font-size: 18px;
    }
    .heroOverlay .buttonWrap button{
        width: 130px;
        height: 40px;
    }
    .cardsWrapper{
        flex-wrap: wrap;
        row-gap: 30px;
    }
    .cardsWrapper .card{
        clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);

    }
    .cardsWrapper .card h3{
        font-size: 20px;
    }
    .cardsWrapper svg{
        font-size: 35px;
    }
    .demoWrapper h3{
        font-size: 20px;
        width: 100%;
    }
    .bottomCards h2{
        font-size: 35px;
    }
    .bottomCardsWrapper{
        flex-wrap: wrap;
        row-gap: 30px;
    }
    .bottomCardsWrapper .bottomCard{
        clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
    }
    .questions h2{
        font-size: 35px;
    }
    .partners{
        row-gap: 50px;
    }
    .sponsors h1{
        font-size: 40px;
    }
    .sponsors button{
        height: 40px;
        width: 120px;
    }
}
@media(max-width: 850px){
    .heroOverlay p{
        width: 100%;
    }
    .demoCards{
        width: 300px;
    }
    .demoCards .demoCard{
        width: 100%;
    }
}
@media(max-width: 600px){
    .connectCardWrapper{
        row-gap: 30px;
    }
    .bottomHero .moon{
        width: 400px;
    }
    .bottomHeroOverlay{
        margin-top: -525px;
    }
}
@media(max-width: 500px){
    .heroOverlay{
        height: 100vh;
        row-gap: 20px;
    }
    .heroOverlay h1{
        font-size: 27px;
    }
    .heroOverlay p{
        font-size: 14px;
    }
    .heroOverlay .buttonWrap button{
        width: 100px;
        height: 35px;
    }
    .cardsWrapper{
        flex-wrap: wrap;
        row-gap: 30px;
    }
    .cardsWrapper .card{
        clip-path: polygon(3% 0%, 100% 0%, 97% 100%, 0% 100%);
        width: 100%;
    }
    .accumulate{
        font-size: 14px;
    }
    .cardsWrapper .card h3{
        font-size: 17px;
    }
    .cardsWrapper .card p{
        font-size: 13px;
    }
    .cardsWrapper svg{
        font-size: 30px;
    }
    .demoWrapper h3{
        font-size: 17px;
        width: 100%;
        line-height: 24px;
    }
    .accumulate2{
        font-size: 14px;
    }
    .bottomCards h2{
        font-size: 27px;
    }
    .bottomCardsWrapper .bottomCard h3{
        font-size: 17px;
    }
    .bottomCardsWrapper .bottomCard p{
        font-size: 13px;
    }
    .bottomCardsWrapper{
        flex-wrap: wrap;
        row-gap: 30px;
    }
    .bottomCardsWrapper .bottomCard{
        clip-path: polygon(3% 0%, 100% 0%, 97% 100%, 0% 100%);
        width: 100%;
    }
    .questions h2{
        font-size: 27px;
    }
    .partners{
        row-gap: 50px;
    }
    .partners img{
        width: auto;
        height: 45px;
    }
    .sponsors{
        text-align: center;
    }
    .sponsors h1{
        font-size: 27px;
    }
    .sponsors button{
        height: 35px;
        width: 120px;
    }
    .connectCard{
        width: 48%;
    }
    .connectCardWrapper{
        column-gap: 2%;
    }
    footer{
        row-gap: 10px;
        align-items: center;
    }
}
@media(max-width: 450px){
    .demoCards{
        width: 100%;
    }
    .demoCards .demoCard{
        width: 100%;
    }
    .bottomHero .moon{
        width: 300px;
    }
    .bottomHeroOverlay{
        margin-top: -400px;
    }
    .connect h3{
        font-size: 20px;
    }
    .connectCard svg{
        font-size: 25px;
    }
    footer small{
        text-align: center;
    }
    footer{
        align-items: center;
        justify-content: center;
    }
    .demoWrapper h3{
        font-size: 17px;
    }
}
