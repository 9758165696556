.referrals{
    font-size: 30px;
    margin-bottom: 24px;
}
.cardWrapper{
    display: flex;
    column-gap: 2%;
    flex-wrap: wrap;
}
.cardWrapper .card{
    width: 32%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    background-color: rgba(59, 59, 59, 1);
    border-radius: 16px;
    align-items: center;
}
.cardTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    text-align: center;
    padding: 10px;
    background-color: rgba(43, 43, 43, 1);
    column-gap: 16px;
    border-radius: 8px;
}
.cardTitle img{
    height: 40px;
    width: auto;
    object-fit: cover;
}
.card h2{
    font-size: 32px;
    font-weight: 600;
    display: flex;
    column-gap: 16px;
    align-items: flex-end;
}
.card h2 small{
    font-size: 16px;
    font-weight: 400;
}
.card h2 img{
    height: 20px;
    width: auto;
    object-fit: cover;
}


.boostReferrals{
    background-image: url("../assets/images/boostreferralsbackground.png");
    margin-top: 72px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height:fit-content;
    border-radius: 16px;
}
.boostReferralOverlay{
    display: flex;
    padding: 24px;
    flex-direction: column;
    row-gap: 16px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 16px;

}
.boostReferrals h3{
    color: black;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
}
.boostReferrals p{
    color: black;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}
.referralLink{
    width: fit-content;
    height: fit-content;
    padding: 15px 24px;
    column-gap: 16px;
    border-radius: 8px;
    background-color: var(--title-background);
    display: flex;
    align-items: center;
}
.referralLink p{
    color: white;
    font-size: 14px;
    font-weight: 400;
}
.referralLink svg{
    font-size: 20px;
}

.referralList{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.referralList h3{
    font-size: 20px;
}
.referralList .wrapper{
    padding-top: 0px;
}
.referralList .title{
    justify-content: center;
}
.referral{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    padding: 8px 20px;
    flex-wrap: wrap;
    row-gap: 10px;
}
.referral img{
    height: 40px;
    width: 40px;
    border-radius: 100%;
}
.referralInfo{
    display: flex;
    align-items: center;
    column-gap: 16px;
}
.referrDate{
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    align-items: center;
}
.referrDate small{
    font-size: 12px;
    color: rgba(75, 85, 99, 1);
}
.referrDate span{
    font-size: 14px;
    color: rgba(75, 85, 99, 1);
}
.referralInfo p{
    font-size: 16px;
    color: rgba(75, 85, 99, 1);
}


.powerHolderWrapper{
    padding-top: 72px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.powerHolderWrapper h3{
    font-size: 20px;
}
.powerHolders{
    background-color: var(--dashboard-background);
    padding: 24px;
    border-radius: 16px;
}
.powerUsersContainer{
    width: 100%;
    overflow-y: scroll;
    height: 270px;
}
.powerUsersContainer::-webkit-scrollbar{
    background-color: rgba(59, 59, 59, 1);
    width: 2px;
}
.powerUsersContainer::-webkit-scrollbar-thumb{
    background-color: black;
}
.powerUsersWrapper{
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 16px;
}
.powerUsersWrapper .users{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    position: relative;
    align-items: center;
}
.powerUsersWrapper img{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: gray;
}
.powerUsersWrapper .position{
    position: absolute;
    top: 3px;
    right: 15px;
    z-index: 20;
}
.powerUsersWrapper span{
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    background-color: var(--dashboard-background);
}
.powerUsersWrapper h3{
    font-size: 14px;
    font-weight: 600;
}
.powerUsersWrapper p{
    font-size: 17px;
    margin-top: -2px;
    color: black;
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.powerUsersWrapper p img{
    width: 16px;
    height: auto;
    object-fit: cover;
    background-color: transparent;
    border-radius: 0px;
}
.users img{
    background-color: rgba(255, 225, 96, 1);
}

.alertContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  
  .alertBox {
    padding: 20px;
    border: 1px solid #ffc81e;
    border-radius: 8px;
    background-color: #fff8e1;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .alertTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #e65100;
  }
  
  .alertBody {
    font-size: 16px;
    color: #5d4037;
  }
  
  .alertBody a {
    color: #ff6f00;
    text-decoration: none;
  }
  
  .alertBody a:hover {
    text-decoration: underline;
  }
@media(max-width: 1300px){
    .cardWrapper .card{
        padding: 20px;
        row-gap: 20px;
    }
    .cardTitle{
        width: 100%;
        padding: 8px;
    }
    .cardTitle img{
        height: 30px;
    }
    .cardTitle p{
        font-size: 14px;
    }
    .card h2{
        font-size: 28px;
    }
    .card h2 small{
        margin-bottom: 5px;
        font-size: 13px;
    }
    .card h2 img{
        height: 18px;
        margin-bottom: 5px;
    }
}
@media(max-width: 750px){
    .cardWrapper{
        row-gap: 15px;
    }
    .cardWrapper .card{
        width: 49%;
    }
    .cardWrapper .card:last-child{
        width: 100%;
    }
    .boostReferrals h3{
        font-size: 20px;
    }
    .boostReferrals p{
        font-size: 14px;
    }
    .referralLink {
        padding: 10px 14px;
    }
    .referralLink p{
        font-size: 13px;
    }
    .referralLink svg{
        font-size: 16px;
    }
    .boostReferralOverlay{
        padding: 24px 18px;
    }
    .referrals{
        font-size: 25px;
    }
}
@media(max-width: 600px){
    .boostReferrals h3{
        font-size: 18px;
    }
    .boostReferrals p{
        font-size: 13px;
    }
    .cardTitle img{
        height: 25px;
    }
    .cardTitle p{
        font-size: 13px;
    }
    .card h2{
        font-size: 25px;
    }
    .card h2 small{
        margin-bottom: 3px;
        font-size: 13px;
    }
    .card h2 img{
        height: 16px;
        margin-bottom: 3px;
    }
    .referral p{
        font-size: 13px;
    }
    .referrDate small{
        font-size: 10px;
    }
    .referrDate span{
        font-size: 12px;
    }
    .referral img{
        height: 30px;
        width: 30px;
    }
    .referralList .title p{
        font-size: 13px;
    }
    .referralList h3{
        font-size: 16px;
    }
    .powerHolders{
        padding: 18px;
    }
    .powerHolderWrapper h3{
        font-size: 16px;
    }
    .powerUsersWrapper{
        column-gap: 18px;
    }
    .powerUsersWrapper .users{
        row-gap: 5px;
    }
    .powerUsersWrapper img{
        height: 70px;
        width: 70px;
    }
    .powerUsersWrapper .position{
        right: 15px;
    }
    .powerUsersWrapper h3{
        font-size: 13px;
    }
    .powerUsersWrapper p{
        font-size: 15px;
    }
    .powerUsersWrapper p img{
        width: 13px;
    }

}

@media(max-width: 400px){
    .cardWrapper{
        flex-direction: column;
    }
    .cardWrapper .card{
        width: 100%;
    }
    .referrals{
        font-size: 20px;
    }
}
@media(max-width: 350px){
    .referrDate{
        flex-direction: row;
        display: flex;
        column-gap: 8px;
        align-items: center;
    }
}